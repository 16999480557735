import { STORAGE_TOKEN_KEY } from '@/utils';
import { message } from 'antd';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

/**
 * 将请求单例导出去(如果有自定义的需求就自己加)
 */
export const requestSingleton: { getInstance: (options?: AxiosRequestConfig) => AxiosInstance } = (() => {
  let instance: AxiosInstance;

  function init(options?: AxiosRequestConfig): AxiosInstance {
    const axiosInstance: AxiosInstance = axios.create({
      timeout: 1000 * 60 * 10,
      timeoutErrorMessage: '请求超时',
      withCredentials: true,
      // 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
      responseType: 'json',
      ...options,
    });

    axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(STORAGE_TOKEN_KEY);
        let Authorization = '';
        if (token && config.url !== '/auth/oauth/token') {
          Authorization = `bearer ${token}`;
        } else {
          Authorization = 'Basic cGJiLXBsYXRmb3JtLW1hbmFnZW1lbnQ6MTIzNDU2';
        }

        config.headers.Authorization = Authorization;
        return config;
      },
      (error: AxiosError) => {
        message.error(error?.message ?? '请求失败');
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
      message.error(error?.message ?? '请求失败');
      return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use((info: AxiosResponse) => {
      const data = info.data;
      if (data.code && data.code != 200) {
        if (data.code === 401 || data.code === 402) {
          localStorage.removeItem(STORAGE_TOKEN_KEY);
          if (process.env.NODE_ENV !== 'development') {
            window.top.location.href = '/pbb-pc-management/login';
          }
          const error = '登录状态已过期,请您重新登录';
          message.error({ content: error, key: error });
          return Promise.reject(info);
        }

        const error = data.msg || '系统正在抓紧处理中';
        message.error({ key: error, content: error });
        return Promise.reject(data);
      }

      return info;
    }, undefined);

    return axiosInstance;
  }

  return {
    getInstance(options?: AxiosRequestConfig): AxiosInstance {
      if (!instance) {
        instance = init(options);
      }
      return instance;
    },
  };
})();

/**
 * 如果返回满足以status或者success字段区分成功，不需要再手动判断请求是否成功，和给出错误提示
 * 对axios的封装。https://github.com/axios/axios
 */
export function request<T = unknown>(options: AxiosRequestConfig = {}): Promise<T> {
  // 错误情况还需要处理的请自行处理。这里无法处理
  return requestSingleton
    .getInstance()
    .request<T>(options)
    .then((info) => info.data);
}

/**
 * 常见的后端数据返回结构。以泛型传递给request
 */
export interface BaseData<T = unknown> {
  code?: string | number;
  msg?: string;
  data: T;
}

/**
 * 常见的后端分页的数据返回结构。以泛型传递给request
 */
export interface PaginationData<T = unknown> {
  status?: string;
  msg?: string;
  success?: boolean;
  errorMsg?: string;
  errorCode?: number;
  data?: PureData<T>;
}

/**
 * 后端直接返回data对象
 */
export interface PureData<T = unknown> {
  calTotalPageCount: number;
  first: number;
  list: T[];
  pageNum: number;
  pageSize: number;
  total: number;
  totalPageCount: number;
}
