import { message } from 'antd';
import type { RcFile } from 'antd/es/upload';
import OBS from 'esdk-obs-browserjs';
import { request } from './request';
export interface EpPolicyParam {
  /**
   * null
   * 1 图片
   * 2 视频
   * 3 vita
   */
  attachmentType: null | 1 | 2 | 3;

  // 对应请求content-type 文件类型
  contentType?: string;

  // 文件名
  fileName?: string;

  /**
   * 业务名称
   * opus 上传作品
   * companyLogo 上传logo
   * companyAlbum 企业相册
   */
  module: 'opus' | 'companyLogo' | 'companyAlbum';

  // 产品名称默认 雇主端pbb-platform-wx-employer 主播端pbb-platform-wx-anchor  平台 pbb-platform-pc-manage
  productName?: string;

  // 文件 单选
  file?: File | RcFile;
  // 文件 多选
  fileList?: (File | RcFile)[];
}

export interface EpPolicyInfo {
  ak: string;
  bucket: string;
  endpoint: string;
  expiresAt: string;
  key: string;
  policy: string;
  securitytoken: string;
  signature: string;
  sk: string;
  url: string;
}

export namespace EpUpload {
  export const epMakeFileNameWithPath = (file: EpPolicyParam['file']) => {
    const fileType = file.type.split('/').pop();
    const uuid = Math.random().toString(36).substring(3, 10);
    const time = new Date().getTime();
    return `${uuid}-${time}.${fileType}`;
  };
  export const epGetPolicy = async (data: EpPolicyParam): Promise<EpPolicyInfo> => {
    const policy: any = await request({
      url: '/baseinfo/obs/generate/upload/secret',
      method: 'POST',
      data: {
        contentType: data.attachmentType === 2 ? 'video' : 'image',
        productName: 'pbb-platform-pc-manage',
        // fileName,
        module: data.module,
        attachmentType: data.attachmentType,
      },
    });
    return policy.data;
  };

  const getObsClient = ({ access_key_id, secret_access_key, server, securitytoken }) =>
    new OBS({
      access_key_id,
      secret_access_key,
      server,
      security_token: securitytoken,
    });

  export const upload = async (data: EpPolicyParam, policyRes: EpPolicyInfo): Promise<string> => {
    try {
      const { key, ak, sk, endpoint, bucket, url, securitytoken } = policyRes;
      const path = `${key}/${epMakeFileNameWithPath(data.file)}`;
      console.log('upload', path);

      const obsClient = getObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: endpoint,
        securitytoken,
      });
      console.log(obsClient);

      const r = await obsClient.putObject({
        Bucket: bucket,
        Key: path,
        SourceFile: data.file,
      });
      console.log(r);

      const resUrl = `${url}/${path}`;
      console.log('resUrl', resUrl);
      return resUrl;
    } catch (err) {
      console.error(err);
      message.error('上传失败');
      return '';
    } finally {
    }
  };

  export const singleUpload = async (data: EpPolicyParam): Promise<string> => {
    const policyRes = await epGetPolicy(data);
    const url = await upload(data, policyRes);
    return url;
  };

  export const multiUpload = async (data: EpPolicyParam): Promise<string[]> => {
    const policyRes = await epGetPolicy(data);
    const promiseList: Promise<string>[] = [];
    for (let i = 0; i < data.fileList.length; i++) {
      promiseList.push(upload({ ...data, file: data.fileList[i] }, policyRes));
    }
    const res: string[] = await PromiseAll(promiseList);
    console.log('multipartUpload', res);
    return res;
  };

  const PromiseAll = (iterator: Promise<string>[]): Promise<string[]> => {
    const promises: any = Array.from(iterator); // 对传入的数据进行浅拷贝，确保有遍历器
    const len = promises.length; // 长度
    const data: string[] = []; // 用来存放返回的数据数组
    // biome-ignore lint/suspicious/noAsyncPromiseExecutor: <explanation>
    return new Promise(async (resolve) => {
      for (let i = 0; i < promises.length; i++) {
        await promises[i]
          .then((res: any) => {
            data[i] = res;
            if (i + 1 === len) {
              resolve(data);
            }
          })
          .catch((err: any) => {
            data[i] = '';
            if (i + 1 === len) {
              resolve(data);
            }
          });
      }
    });
  };
}
