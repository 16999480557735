import { Button, Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from '../filterItems.module.less';
import { FilterItemLabel } from '../utils';
import type { FilterSelect } from './filterSelect';

export const FilterSelectComponent = observer(({ store }: { store: FilterSelect }) => {
  const {
    value,
    onChange,
    disabled,
    style,
    className,
    placeholder,
    allowClear,
    showSearch,
    data,
    searchValue,
    onSearch,
    mode,
    label,
    labelWidth,
    required,
    showChooseAll,
    handleChooseAll,
  } = store;
  return (
    <div
      className={`filterSelect ${className}`}
      style={style}
    >
      <FilterItemLabel
        label={label}
        labelWidth={labelWidth}
        required={required}
      />
      <Select
        allowClear={allowClear}
        autoClearSearchValue={true}
        disabled={disabled}
        popupMatchSelectWidth={true}
        dropdownRender={
          mode && showChooseAll
            ? (menu) => (
                <>
                  {menu}
                  <div className={styles.filterSelectChooseAll}>
                    <section>
                      <Button
                        onClick={handleChooseAll}
                        size="small"
                        type="primary"
                      >
                        全选
                      </Button>
                    </section>
                  </div>
                </>
              )
            : null
        }
        maxTagCount="responsive"
        mode={mode}
        onChange={onChange}
        onDropdownVisibleChange={(open) => {
          if (!open) {
            onSearch('');
          }
        }}
        onSearch={onSearch}
        options={data}
        placeholder={placeholder}
        searchValue={searchValue}
        showSearch={showSearch}
        style={{ width: `calc(100% - ${labelWidth}px)` }}
        value={value}
        virtual={true}
        optionFilterProp={'label'}
      />
    </div>
  );
});
