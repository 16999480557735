import { InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import _ from 'underscore';
import { FilterItemLabel, throttleTime } from '../utils';
import type { FilterInputNumberGroup } from './filterInputNumberGroup';

@observer
export class FilterInputNumberGroupComponent extends React.Component<{ store: FilterInputNumberGroup }> {
  public handlePressEnter: React.KeyboardEventHandler = _.throttle(() => {
    if (!this.props.store._isLoading) {
      if (typeof this.props.store.onPressEnter === 'function') {
        this.props.store.onPressEnter();
      }
    }
  }, throttleTime);

  render() {
    const {
      value,
      placeholder,
      onMinChange,
      onMaxChange,
      step,
      disabled,
      style,
      className,
      label,
      labelWidth,
      required,
    } = this.props.store;
    return (
      <div
        className={`filterInputNumberGroup ${className}`}
        style={style}
      >
        <FilterItemLabel
          label={label}
          labelWidth={labelWidth}
          required={required}
        />
        <InputNumber
          disabled={disabled}
          max={Number.MAX_SAFE_INTEGER}
          onChange={onMinChange}
          onPressEnter={this.handlePressEnter}
          placeholder={placeholder[0]}
          step={step}
          value={value[0]}
        />
        <span>至</span>
        <InputNumber
          disabled={disabled}
          max={Number.MAX_SAFE_INTEGER}
          onChange={onMaxChange}
          onPressEnter={this.handlePressEnter}
          placeholder={placeholder[1]}
          step={step}
          value={value[1]}
        />
      </div>
    );
  }
}
