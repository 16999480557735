import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';
import { ENUM_FILTER_ITEM_TYPE } from '../types';
import { FilterItemLabel } from '../utils';
import { FormatDateType } from './filterDate';
import type { FilterDateStartOrEnd } from './filterDateStartOrEnd';

export const FilterDateStartOrEndComponent = observer(({ store }: { store: FilterDateStartOrEnd }) => {
  const {
    value,
    handleChange,
    placeholder,
    format,
    label,
    className,
    style,
    disabled,
    type,
    labelWidth,
    allowClear,
    required,
    containerRef,
    fixPanelHideNotSetTime,
    picker,
    disabledDate,
    disabledTime,
  } = store;
  const newClassName = ['filterDate', className].filter(Boolean).join(' ');
  return (
    <div
      className={newClassName}
      ref={containerRef}
      style={style}
    >
      <FilterItemLabel
        label={label}
        labelWidth={labelWidth}
        required={required}
      />
      <DatePicker
        allowClear={allowClear}
        disabled={disabled}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        format={format}
        onChange={handleChange}
        onOpenChange={fixPanelHideNotSetTime}
        picker={picker}
        placeholder={placeholder}
        showNow={false}
        showTime={
          format === FormatDateType.defaultFormat
            ? {
                hideDisabledOptions: true,
                defaultValue:
                  type === ENUM_FILTER_ITEM_TYPE.dateStart
                    ? dayjs('00:00:00', 'HH:mm:ss')
                    : dayjs('23:59:59', 'HH:mm:ss'),
              }
            : false
        }
        value={value}
      />
    </div>
  );
});
