import { action, computed, observable } from 'mobx';
import type React from 'react';
import type { FilterItem, FilterItemsParams } from './filterItems';
import { FilterItems } from './filterItems';

export interface NormalProgrammeParams extends FilterItemsParams {
  /**
   * 回车、查询回调
   */
  handleSearch?: (...args: any) => Promise<any>;

  /**
   * 自定义的button。会覆盖默认的重置、查询
   */
  button?: React.ReactNode;

  /**
   * 是否显示button
   */
  showButton?: boolean;

  /**
   * 是否显示折叠
   */
  showCollapse?: boolean;
}

export class NormalProgramme {
  constructor(options: Partial<NormalProgrammeParams> = {}) {
    this.filterItems = new FilterItems({
      filterItems: (options.filterItems || []).map((item) => ({
        onPressEnter: this.handleSearch,
        ...item,
      })),
      dict: options.dict,
    });
    this.searchCallback = options.handleSearch;

    if (options.button) {
      this.button = options.button;
    }

    if (options.showButton != null) {
      this.showButton = options.showButton;
    }

    if (options.showCollapse != null) {
      this.showCollapse = Boolean(options.showCollapse);
    }
  }

  private searchCallback: NormalProgrammeParams['handleSearch'];

  @observable public showCollapse = true;

  @observable public isSearch = false;

  @action public handleSearch = () => {
    this.isSearch = true;

    this.filterItems
      .validator()
      .then(() => {
        // 设置所有查询项的loading状态
        this.filterItems.originData.forEach((item) => {
          item._isLoading = true;
        });

        if (typeof this.searchCallback === 'function') {
          try {
            return this.searchCallback();
          } catch (error) {
            console.log('error:筛选组件 handleSearch', error);
            return Promise.reject();
          }
        } else {
          return Promise.resolve();
        }
      })
      .finally(() => {
        this.isSearch = false;

        // 重置所有查询项的loading状态
        this.filterItems.originData.forEach((item) => {
          item._isLoading = false;
        });
      });
  };

  /**
   * 查询项instance
   */
  public filterItems: FilterItems;

  /**
   * 自定义的button。会覆盖默认的重置、查询
   */
  public button: NormalProgrammeParams['button'] = null;

  @observable public showButton = true;

  @action public reset = () => {
    this.filterItems.reset();
  };

  @computed
  public get notCollapseData(): FilterItem[] {
    // 不折叠的原路返回,兼容可能是竖直方向一个一行
    if (!this.showCollapse) {
      return this.filterItems.actualData;
    }

    let totalSpan = 48;
    const result: FilterItem[] = [];

    for (let i = 0; i < this.filterItems.actualData.length && totalSpan > 0; i++) {
      const item = this.filterItems.actualData[i];
      totalSpan -= item.span;
      if (totalSpan >= 0) {
        result.push(item);
      }
    }

    return result;
  }

  @computed
  public get collapseData(): FilterItem[] {
    const notCollapseFields = this.notCollapseData.map((item) => item.field);
    return this.filterItems.actualData.filter((item) => !notCollapseFields.includes(item.field));
  }
}
