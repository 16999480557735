import { Input } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import _ from 'underscore';
import { MultipleSearch } from '../multipleSearch';
import { FilterItemLabel, throttleTime } from '../utils';
import type { FilterInput } from './filterInput';

@observer
export class FilterInputComponent extends React.Component<{ store: FilterInput }> {
  public handlePressEnter: React.KeyboardEventHandler = _.throttle(() => {
    if (!this.props.store._isLoading) {
      if (typeof this.props.store.onPressEnter === 'function') {
        this.props.store.onPressEnter();
      }
    }
  }, throttleTime);

  render() {
    const {
      value,
      onChange,
      placeholder,
      allowClear,
      disabled,
      style,
      className,
      label,
      labelWidth,
      required,
      isMultipleSearch,
      splitSymbol,
    } = this.props.store;
    return (
      <div
        className={`filterInput ${className}`}
        style={style}
      >
        <FilterItemLabel
          label={label}
          labelWidth={labelWidth}
          required={required}
        />
        <Input
          allowClear={allowClear}
          disabled={disabled}
          onChange={(event) => onChange(event.target.value)}
          onPressEnter={this.handlePressEnter}
          placeholder={placeholder}
          suffix={
            isMultipleSearch ? (
              <MultipleSearch
                callback={onChange}
                splitSymbol={splitSymbol}
                value={value}
              />
            ) : null
          }
          value={value}
        />
      </div>
    );
  }
}
