/**
 * 格式化number
 * @param num 数字
 */
export function formatNumber(num: unknown): number {
  const newNum = Number(num);
  return Number.isFinite(newNum) ? newNum : 0;
}

/**
 * 浮点数相加
 * @param num1 数字1
 * @param num2 数字2
 */
export function add(num1: unknown, num2: unknown): number {
  const s1 = String(formatNumber(num1));
  const s2 = String(formatNumber(num2));
  const decimalLength1 = (s1.split('.')[1] || '').length;
  const decimalLength2 = (s2.split('.')[1] || '').length;

  const maxDecimalLength = Math.max(decimalLength1, decimalLength2);
  const newNum1 = Number(s1.replace('.', '')) * Math.pow(10, maxDecimalLength - decimalLength1);
  const newNum2 = Number(s2.replace('.', '')) * Math.pow(10, maxDecimalLength - decimalLength2);
  return (newNum1 + newNum2) / Math.pow(10, maxDecimalLength);
}

/**
 * 浮点数相减
 * @param num1 减数
 * @param num2 被减数
 */
export function subtract(num1: unknown, num2: unknown): number {
  // @ts-ignore
  return add(num1, -num2);
}

/**
 * 浮点数相乘
 * @param num1 数字1
 * @param num2 数字2
 */
export function multiple(num1: unknown, num2: unknown): number {
  const s1 = String(formatNumber(num1));
  const s2 = String(formatNumber(num2));
  const decimalLength = (s1.split('.')[1] || '').length + (s2.split('.')[1] || '').length;

  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, decimalLength);
}

/**
 * 保留小数点位数。Number.prototype.toFixed有bug
 * @param num 数字
 * @param decimalLength 小数点位数
 */
export function toFixed(num: unknown, decimalLength = 0): string {
  const newNum = Math.floor(multiple(num, Math.pow(10, decimalLength + 1)));
  const patchResult = (Math.floor(newNum / 10) + (newNum % 10 > 4 ? 1 : 0)) / Math.pow(10, decimalLength);
  const result = String(patchResult).split('.');
  const resultInt = result[0];
  let resultDecimal = result[1] || '';
  while (resultDecimal.length < decimalLength) {
    resultDecimal = `${resultDecimal}0`;
  }

  return resultDecimal ? `${resultInt}.${resultDecimal}` : resultInt;
}

/**
 * 千分位
 * @param num 数字
 */
export function thousandthSeparator(num: unknown): string {
  return String(formatNumber(num)).replace(/\d+/, (n) => n.replace(/(?!^)(?=(\d{3})+$)/g, ','));
}

export function toString(str?: unknown): string {
  if (str == null) {
    return '';
  }

  return String(str);
}

export function combinePath(pathPrefix: string, relativePath: string): string {
  return `${pathPrefix.replace(/(\/+)$/, '')}/${relativePath.replace(/^(\/+)/, '')}`;
}

export function getStaticResourceUrl(relativePath: string): string {
  let urlPrefix = 'https://pbbfront.pinbobo.cn/';
  try {
    if (process.env.CDN) {
      urlPrefix = process.env.CDN;
    }
  } catch (e) {
    //
  }

  return combinePath(urlPrefix, relativePath);
}

/**
 * 对象key转换
 * */
export const transformKeys = (obj: Record<string, any>, keyMap: Record<string, string>): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeys(item, keyMap));
  }

  if (typeof obj === 'object' && obj !== null) {
    const result: Record<string, any> = {};
    for (const key in obj) {
      // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
      if (obj.hasOwnProperty(key)) {
        const newKey = keyMap[key] || key;
        result[newKey] = transformKeys(obj[key], keyMap);
      }
    }
    return result;
  }

  return obj;
};

/**
 * 多维数组拿key对应的值
 * */
export function extractValuesByKey(array: any[], key: string, childKey: string): any[] {
  const result: any[] = [];

  array?.forEach((item) => {
    if (item[key]) {
      result.push(item[key]);
    }
    if (Array.isArray(item[childKey])) {
      result.push(...extractValuesByKey(item.nested, key, childKey));
    }
  });

  return result;
}

/**
 * 求两个数组的交集
 * @param array1 第一个数组
 * @param array2 第二个数组
 * @returns 交集数组
 */
export const getIntersection = (
  arr1: Record<string, any>[],
  arr2: Record<string, any>[],
  byKey: string,
): Record<string, any>[] => {
  const set1 = new Set(arr1.map((item) => item[byKey]));
  const intersection = arr2.filter((item) => set1.has(item[byKey]));
  return intersection;
};
